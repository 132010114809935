import PreLoader from '@/components/Common/PreLoader';
import Button from '@/components/Common/Buttons/Button/Button';
import SearchResultsNav from '@/components/Components/SearchResultsNav/SearchResultsNav';
import PropertyAd from '@/components/Components/PropertyAd/PropertyAd';
import AdvancedSearchModal from '@/components/Components/AdvancedSearchModal/AdvancedSearchModal';
import {GoogleMap, Marker} from 'vue3-google-map';
import mapOptions from "@/views/SearchResult/MapOptions";

export default {
    name: 'PropertySearchPage',
    components: {
        Button,
        SearchResultsNav,
        PropertyAd,
        AdvancedSearchModal,
        GoogleMap,
        Marker,
        PreLoader,
    },
    data() {
        return {
            openMapMobile: true,
            properties: null,

            // Map
            mapZoom: 7,
            mapCenter: {lat: 42.49988365271612, lng: 25.231609612925894},
            markers: [],
            selectedMarker: null,

            // Pagination
            offset: 0,
            limit: 12,
            isMoreResultsLoading: false,
            hasMoreResults: false,

            order: 'createdAt.DESC',

            selectedCity: 1,
            cityCoordinates: {
                1: {lat: 42.69919171842598, lng: 23.318798578945966, zoom: 12},
                3: {lat: 42.14278371401564, lng: 24.747739232986703, zoom: 12},
                4: {lat: 42.504934503679166, lng: 27.46234621767227, zoom: 12},
                5: {lat: 43.075702591172124, lng: 25.617558245268487, zoom: 12},
                6: {lat: 43.21393730481615, lng: 27.914846809165006, zoom: 12},
                7: {lat: 42.42592413598081, lng: 25.634439229719856, zoom: 12},
            },
        };
    },
    setup() {
        const mapOption = mapOptions;
        return {mapOption};
    },
    watch: {
        '$route.params': {
            handler: 'handleRouteParamsChange',
            deep: true,
            immediate: true
        }
    },
    async mounted() {
        this.map = this.$refs.map;
    },
    methods: {
        toggleAdvancedSearchModal() {
            this.$store.dispatch('utils/toggleAdvancedSearch', {});
        },
        toggleMapMobile() {
            this.openMapMobile = !this.openMapMobile;
        },
        selectProperty(propertyId) {
            const offset = -150 + this.$refs['prop' + propertyId][0].offsetTop;
            window.scrollTo({top: offset});
            this.selectMarker(propertyId);
        },
        handleRouteParamsChange(newVal, oldVal) {
            this.updateSearchResults();
        },
        selectMarker(propertyId) {
            this.selectedMarker = propertyId;
            this.loadMarkers();
            let vm = this;
            this.markers.forEach((marker, index) => {
                if (vm.selectedMarker === marker.propertyId) {
                    let options = marker;
                    const full = location.protocol + '//' + location.host;
                    options.icon.url = full + '/static/marker' + (marker.isVip ? '-vip' : '') + '-selected.png';
                    vm.$refs['mk' + marker.propertyId][0].marker.setOptions(options);
                }
            });
        },
        async sortResults(option) {
            if (option === 'price_ascending') {
                this.order = 'rentPrice.ASC';
            }
            if (option === 'price_descending') {
                this.order = 'rentPrice.DESC';
            }
            if (option === 'create_date') {
                this.order = 'createdAt.DESC';
            }

            this.offset = 0;
            await this.updateSearchResults();
        },
        getMarkerOptions(property) {
            const isVip = property.isVip;
            const fullUrl = location.protocol + '//' + location.host;

            return {
                propertyId: property.id,
                isVip: property.isVip,
                position: {
                    lat: parseFloat(property.gpsCoordinates.long),
                    lng: parseFloat(property.gpsCoordinates.lat),
                },
                label: {
                    text: property.price + this.$t('currency-type'),
                    color: 'white',
                    fontSize: '10px',
                },
                icon: {
                    url: fullUrl + '/static/marker' + (isVip ? '-vip' : '') + '.png',
                    size: {width: isVip ? 80 : 60, height: 22},
                    anchor: {x: 20, y: 20},
                    // scaledSize: { width: 40, height: 40 },
                    // labelOrigin: { x: 20, y: -10 },
                },
            };
        },
        loadMarkers() {
            let vm = this;
            this.properties.forEach(property => {
                vm.markers.push(vm.getMarkerOptions(property));
            });
        },
        async updateSearchResults(append = false) {
            const params = this.$route.query;
            const limit = this.limit;
            const offset = this.offset;
            const order = this.order;

            if (params.hasOwnProperty('city_id') && params.city_id !== null) {
                this.selectedCity = params.city_id;
                if (this.cityCoordinates.hasOwnProperty(this.selectedCity)) {
                    this.mapCenter = this.cityCoordinates[this.selectedCity];
                    this.mapZoom = this.cityCoordinates[this.selectedCity].zoom;
                }
            }

            const result = await this.$store.dispatch('property/getProperties', {params, offset, limit, order});
            this.hasMoreResults = result.length >= this.limit;

            if (result === null) {
                this.hasMoreResults = false;
                return;
            }

            if (append && this.properties !== null) {
                this.properties = [...this.properties, ...result];
            } else {
                this.properties = result;
            }

            this.loadMarkers();
        },
        async showMoreResults() {
            if (this.isMoreResultsLoading) return;
            this.isMoreResultsLoading = true;
            this.offset += this.limit;
            await this.updateSearchResults(true);
            this.isMoreResultsLoading = false;
        }
    }
};
